<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->

      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> UPDATE FILE</v-card-title>
        <form @submit.prevent="CreateData" enctype="multipart/form-data" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-text-field
              style="display: none"
              label="Member"
              v-model="databerkas.id_member"
              dense
              outlined
            ></v-text-field>

            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Berkas</v-card-text>
              <img v-if="databerkas.berkas.length == 0" :src="defautlPhoto()" width="150px" />

              <div v-if="databerkas.berkas.length != 0">
                <v-row>
                  <v-col cols="6" sm="6" style="cursor: pointer">
                    <v-img :src="showPhoto()">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </div>

              <div>
                <v-btn type="submit" color="primary" @click.prevent="showFileChooser" class="me-3 mt-4">
                  Choose File
                </v-btn>
              </div>

              <input style="display: none" ref="input" type="file" id="berkas" @change="uploadPhotoBerkas" />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field label="Keterangan" v-model="databerkas.keterangan" dense outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Update </v-btn>

              <router-link :to="{ name: 'berkas-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Files',
          disabled: false,
          href: '/admin/berkas',
        },
        {
          text: 'Update Files',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      ext: [],

      databerkas: {
        berkas: '',
        keterangan: '',
      },
      errors: [],
      clients: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.getBerkas()
      // this.showPhoto()
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getBerkas() {
      localStorage.getItem('token')
      let uriClients = process.env.VUE_APP_ROOT_API + `/api/berkas/${this.$route.params.id_berkas}/show`
      this.axios.get(uriClients).then(response => {
        this.databerkas = response.data.berkas
      })
    },
    showPhoto() {
      let img = this.databerkas.berkas
      if (img.length > 100) {
        let pisahtitikkoma = img.split(';')
        let pisahgarismiring = pisahtitikkoma[0].split('/')
        let extension = pisahgarismiring[1]
        this.ext = extension

        let pdf = {
          berkas: [this.databerkas.berkas, process.env.VUE_APP_ROOT_API + `/images/berkas/pdf.jpg`],
        }

        let image = {
          berkas: [this.databerkas.berkas, this.databerkas.berkas],
        }

        if (this.ext == 'png' || this.ext == 'jpg' || this.ext == 'jpeg') {
          console.log(image.berkas[1])
          return image.berkas[1]
        } else if (this.ext == 'pdf') {
          return pdf.berkas[1]
        }
        // return this.databerkas.berkas
      } else if (img != null) {
        return (
          process.env.VUE_APP_ROOT_API + `/images/berkas/` + this.databerkas.id_member + `/` + this.databerkas.berkas
        )
      } else {
        return process.env.VUE_APP_ROOT_API + `/images/berkas/no-images.png`
      }
    },
    showFileChooser() {
      this.$refs.input.click()
    },
    uploadPhotoBerkas(event) {
      if (event.target.files[0].size > 2000000) {
        this.$swal.fire({
          title: 'Warning!',
          text: 'File size too large! File size must be smaller than 2MB!',
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
        })
      } else {
        this.databerkas.berkas = event.target.files[0]

        let readerPhoto = new FileReader()
        readerPhoto.readAsDataURL(this.databerkas.berkas)
        readerPhoto.onload = event => {
          this.databerkas.berkas = event.target.result
        }
      }
    },

    defautlPhoto() {
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/berkas/no-images.png`
      return imageDefault
    },

    CreateData(e) {
      // console.log('id_member: ' + this.users.id_member)

      if (this.$data.databerkas.berkas.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Berkas required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.databerkas.keterangan.length === 0) {
        // this.errors.push('Password harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Keterangan required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + `/api/berkas/${this.$route.params.id_berkas}`

        this.axios
          .post(uri, {
            id_member: this.databerkas.id_member,
            // berkas: this.databerkas.berkas[0].berkas,
            berkas: this.databerkas.berkas,
            keterangan: this.databerkas.keterangan,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Berkas created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })
          })
          .catch()

        e.preventDefault()
        setTimeout(() => {
          this.$router.go(-1)
        }, 3000)
      }
    },
  },
}
</script>
